import * as React from "react";
import axios from "axios";
import * as style from "./Home.style";
import bg from "../../assets/sports-betting-game-bg.png";
import hero from "../../assets/hero.png";
import title from "../../assets/title-image.png";
import { Icon } from "../../components/index";
import { colors } from "../../styles/index";
import { useDisplayContext } from "../../utility/hooks";
import { MobileHome } from "./MobileHome";
import { validation } from "../../utility";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
const API_KEY = "SzzmUoeMc1bq9dHNA71x3AmEerqVXS6r";
export const Home: React.FC = () => {
  const [email, setEmail] = React.useState<string>("");
  const emailRef = React.useRef("");
  const [emailError, setEmailError] = React.useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareLink, setShareLink] = React.useState("");
  const [ip, setIP] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const display = useDisplayContext();

  // Get the IP address on page load, retry if the call fails
  const getIp = async () => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        setIP(res.data.IPv4);
      })
      .catch(() => {
        setTimeout(() => {
          getIp();
        }, 1000);
      });
  };
  React.useEffect(() => {
    getIp();
    // Handle enter button for input submission
    const submitOnEnter = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        onSubmit();
      }
    };
    window.addEventListener("keypress", submitOnEnter);
    return () => {
      window.removeEventListener("keypress", submitOnEnter);
    };
  }, []);

  React.useEffect(() => {
    emailRef.current = email;
  }, [email]);

  // validate email, then register with prefinery
  const onSubmit = () => {
    if (!emailRef.current) {
      setEmailError("Please enter your email");
    } else if (!validation.emailRegex.test(emailRef.current)) {
      setEmailError("Please enter a valid email");
    } else {
      setLoading(true);
      axios
        .post(
          `https://api.prefinery.com/api/v2/betas/1qkpdp9e/testers?api_key=${API_KEY}`,
          {
            tester: {
              email: emailRef.current,
              status: "applied",
              referrer_id: searchParams.get("r"),
              profile: {
                user_agent: window.navigator.userAgent,
                ip,
              },
            },
          },
        )
        .then((res) => {
          setLoading(false);
          toast.success(
            "Submission received. Share your link to start earning site credit!",
          );
          setShareLink(res.data.share_link);
        })
        .catch(({ response }) => {
          setLoading(false);
          if (
            response &&
            response.data.errors &&
            response.data.errors.length &&
            response.data.errors[0].code === 2310
          ) {
            setEmailError("You've already signed up!");
          } else {
            setEmailError(
              "There was an error signing up. Please try again. If this continues to fail, contact us via the footer link to sign up.",
            );
          }
        });
    }
  };

  return display.isMobile ? (
    <MobileHome
      email={email}
      emailError={emailError}
      setEmail={setEmail}
      loading={loading}
      onSubmit={onSubmit}
      shareLink={shareLink}
    />
  ) : (
    <div className={style.component}>
      <div className={style.container}>
        {/* <img className={style.background} src={bg} /> */}
        <div className={style.content}>
          {/* <Icon.Logo
            width={155}
            height={24}
            fill={colors.primary}
            className={style.logo}
          />
          
          <div className={style.smallTitle}>Coming September 6th.</div>
          <div className={style.subTitle}>
            <div>Discover. Pick. Win.</div>
          </div> */}
          <div className={style.titleImageContainer}>
            <img
              className={style.titleImage}
              src={title}
              alt="units daily fantasy"
            />
          </div>
          <div className={style.inputContainer}>
            <input
              className={style.input}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter your email..."
              disabled={!!shareLink}
            />
            <div
              className={style.signUpButton}
              onClick={onSubmit}
              id="submit_button"
            >
              {loading ? <Icon.Spinner size={18} /> : "Step Inside"}
            </div>
          </div>
          {emailError && <div className={style.error}>{emailError}</div>}{" "}
          {!!shareLink && (
            <div className={style.shareSection}>
              <div className={style.shareTitle}>Thank you!</div>
              <div className={style.shareDetail}>
                We have added your email address to the list — stay tuned for
                launch on September 6th.
              </div>
              <div className={style.shareTitle}>
                Want to earn in-app site credit when we launch?
              </div>
              <div className={style.shareDetail}>
                Get $5 in site credit for every friend that signs up using your
                unique link:
              </div>
              <div
                className={style.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(shareLink || "");
                }}
                id="submit_button"
              >
                Copy to Clipboard
              </div>
              {/* <a
                className={style.shareTitle}
                href={shareLink}
                style={{ color: colors.primary }}
              >
                {shareLink}
              </a> */}
            </div>
          )}
          {/* <div className={style.heroImageContainer}>
            <img
              className={style.heroImage}
              src={hero}
              alt="units daily fantasy"
            />
          </div> */}
        </div>
      </div>

      <div className={style.footer}>
        <div>
          <a className={style.contactUs} href="mailto:sup@playunits.co">
            Contact us
          </a>
        </div>
        <div>&copy; Copyright 2023</div>
      </div>
    </div>
  );
};
