export const toShortDateUTC = (date: Date) => {
  return `${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()}-${date.getUTCFullYear()}`;
};

export const toLocalDateTime = (date: Date) => {
  return (
    date.toLocaleDateString("en-us") + " " + date.toLocaleTimeString("en-us")
  );
};
