import React, { useState } from "react";
import * as Components from "../components";
import { style as s } from "typestyle";

export const ResponsibleGaming: React.FC = () => {
  return (
    <div style={{ color: "white", padding: 40 }}>
      <Components.MarkdownRenderer>{`
# Responsible Gaming resources

## Overview

Units is changing the way customers enjoy daily fantasy sports. We are committed to providing a fantasy platform that is safe, fair, reliable, and with the highest level of integrity. We have developed our platform in a way that ensures users can play within their means. Units takes responsible gaming very seriously. Below will highlight the tools and resources available to all users in order to do everything possible to prevent problem gaming.

## Need help?

If you have questions or concerns about your own gaming behavior or about that of a friend or family member, good places to start are the [NCPG](https://www.ncpgambling.org/).

You can also contact a 24 Hour Confidential National Helpline Number: 1-800-522-4700 or New York Specific Number: 877-846-7369 for additional assistance provided by the NCPG.

If you are in Arizona, please see Arizona specific responsible gaming resources below:

If you have a gambling problem, help is available from the toll-free Arizona statewide helpline at 1-800-NEXT-STEP (1-800-639-8783) or text NEXT-STEP to 53342.

You may also visit <https://problemgambling.az.gov>.

If you are in Maryland, please see Maryland specific responsible gaming resources below:

Please play responsibly, for help visit [mdgamblinghelp.org](https://www.mdgamblinghelp.org/) or call 1-800-GAMBLER.

If you are in Pennsylvania, please see Pennsylvania specific responsible gaming resources below:

- [For information on PA self-exclusion and treatment information](https://responsibleplay.pa.gov/)

- [Pennsylvania Department of Drug and Alcohol Programs' gambling disorder treatment provider list](https://apps.ddap.pa.gov/gethelpnow/ProviderSearch.aspx?zip)

- [Council on Compulsive Gambling of Pennsylvania](http://www.pacouncil.com/)

- [National Council on Problem Gambling](http://www.ncpgambling.org/)

- [Gambler's Anonymous of Pennsylvania](http://www.gamblersanonymous.org/ga/locations/state/table/PA/na/na/na/na/10?#gmap-nodemap-gmap0)

- [PGCB's Office of Compulsive and Problem Gambling](https://responsibleplay.pa.gov/)

-   PA or National Suicide prevention number and [link](https://suicidepreventionlifeline.org/)- 1-800-273-TALK(8255)]

- [PGCB Signs, Rules, and Myths on Problem Gambling](https://responsibleplay.pa.gov/wp-content/uploads/Signs_Rules_Myth_Fact.pdf)

- [GAM-ANON](https://www.gam-anon.org/)

- [PA Self-Exclusion Enrollment](https://gamingcontrolboard.pa.gov/SelfExclusion/Default.aspx?ReturnUrl=%2fSelfExclusion%2f)

## State specific limitations on consumer deposits

In accordance with state requirements, Units will restrict deposits in the states listed below. There are instances where these limits can change upon further documentation provided from the customer. These limits will apply, unless the user has requested a change in their limits and confirmed them following the cool down period. The following states have state limitations applied, which will be enforced if they are exceeded, regardless of the user's own limitations.

Massachusetts residents, the deposit limit level is $1,000 per 31 days.

Maryland residents and people located in Maryland, the deposit limit is $5,000 per 31 days.

Tennessee residents and people located in Tennessee, the deposit limit level is $2,500 per 31 days.

## Know your limits

At Units, we want our customers to enjoy playing fantasy contests responsibly. Below are some tips to help manage a safe and enjoyable fantasy contest experience.

-   Establish limits for depositing and spending before you start playing.

-   Never enter fantasy contests if it interferes with your daily responsibilities.

-   Never enter fantasy contests if you are under the influence of alcohol or any other substance.

## Stay safe when you play with us

Your privacy and protection are important to us. Here are some tips and links to enhance your security.

Reset your password

If you forget your account password, or you suspect that any unauthorized person may know it, you should immediately notify us. You can always change your password at any time by clicking on any "Change Password" link available either on our login page or within your account. We will send a password reset link to your email address. By clicking on that password reset link, you will be able to create a new password.

Keep others away from using your account

Don't forget to lock your device when you're not playing. Remember, letting other people access your Units account is strictly prohibited. If another person does use your account, you are responsible for their actions. Always lock your device when stepping away in order to help prevent others from accessing your account.

Recognize unauthorized use of your account

You can frequently compare your payment method statements against your account's known deposits to look for transactions you don't recognize. If you think your account has been compromised, please change your password, consider enabling strong authentication, and [contact us](mailto:support@playunits.co).

Transaction history

You can always access your Transaction History at any time. Feel free to periodically check this tool in your account section to confirm all transactions are yours.

## Concerned about a family member?

If you believe that a member of your family is a problem gamer or is overspending please [contact us](mailto:support@playunits.co) for assistance.

## Parental controls

Units verifies all accounts for age and eligibility. If any minors (under 18) are living in your household and have access to your computer, your account information, personal information, banking information, please consider installing parental control software to prevent access to Units. If you believe a minor is found playing Units, please [contact us](mailto:support@playunits.co) for account closure and appropriate measures. Take responsible measures to ensure all devices are locked appropriately before stepping away.


`}</Components.MarkdownRenderer>
    </div>
  );
};
