import { colors } from ".";

export const font = {
  /** Mona Sans */
  main: "Mona Sans",
  /** Inter */
  second: "Inter",
  /** DS Digital */
  third: "DS Digital",
};

export const typography = {
  jumboTitle: {
    fontFamily: font.second,
    fontWeight: 700,
    fontSize: 72,
    lineHeight: "80px",
    letterSpacing: "-.02em",
  },
  cardTitle: {
    fontFamily: font.main,
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "32px",
    letterSpacing: "-.02em",
  },
  header: {
    fontFamily: font.main,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    letterSpacing: "-.01em",
  },
  smallHeader: {
    fontFamily: font.main,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "28px",
    letterSpacing: "-.01em",
  },
  largeButton: {
    fontFamily: font.main,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "24px",
  },
  smallButton: {
    fontFamily: font.main,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: "24px",
    letterSpacing: ".01em",
  },
  bodyBold: {
    fontFamily: font.second,
    fontWeight: 700,
    fontSize: 17,
    lineHeight: "24px",
  },
  body: {
    fontFamily: font.second,
    fontWeight: 400,
    fontSize: 17,
    lineHeight: "24px",
  },
  bodySmallPlus: {
    fontFamily: font.second,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "24px",
  },
  bodySmall: {
    fontFamily: font.second,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: "24px",
  },
  bodyXs: {
    fontFamily: font.second,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
  },
  numbers: {
    fontFamily: font.third,
    fontWeight: 700,
    fontSize: 17,
    lineHeight: "24px",
    letterSpacing: ".01em",
  },
};

export const textColors = {
  main: "#FFFFFF",
  inverse: "#000000",
  light: "#868A91",
  subtle: colors.translucent650,
  inverseSubtle: colors.darkTranslucent700,
  accent: colors.primary,
  error: colors.danger,
};
