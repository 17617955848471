import { style as s } from "typestyle";
import * as Style from "../../styles";

export const wrapper = s({
  position: "relative",
});

export const component = s({
  backgroundColor: Style.colors.translucent150,
  height: 50,
  borderRadius: 2,
  display: "flex",
  alignItems: "center",
  padding: "0px 25px",
  fontFamily: Style.font.main,
  textTransform: "uppercase",
  boxSizing: "border-box",
  border: `1px solid transparent`,
  transition: ".2s ease-in",
  fontSize: 16,
  color: Style.textColors.main,
  outline: "none",
  resize: "none",
  zIndex: 2,
});

export const active = s({
  borderColor: Style.colors.darkTranslucent650,
  backgroundColor: Style.colors.translucent400,
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
});

export const hasError = s({
  borderColor: Style.colors.danger,
});

export const label = s({
  color: Style.textColors.inverseSubtle,
  fontSize: 18,
  position: "absolute",
  left: 25,
  top: 10,
  zIndex: 1,
  transition: ".2s ease-in",
  textAlign: "left",
});

export const filledLabel = s({
  fontSize: 9,
  top: 4,
  left: 4,
});

export const error = s({
  paddingTop: 5,
  color: "orange",
  fontSize: 12,
  textAlign: "left",
  height: 15,
});
