export const shadows = {
  button: "0px 6px 12px rgba(0, 0, 0, 0.16)",
  largeButton: "0px 6px 12px rgba(0, 0, 0, 0.32)",
};

export const borderRadii = {
  small: "10px",
  medium: "16px",
  large: "24px",
  rounded: "32px",
  xRounded: "40px",
};
