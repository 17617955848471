import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Pages from "../pages";
import { DisplayContextProvider } from "./DisplayContext";

// Handle Routing
const Page: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
        <Route path="/privacy" element={<Pages.PrivacyPolicy />} />
        <Route
          path="/responsible-gaming"
          element={<Pages.ResponsibleGaming />}
        />
        <Route path="/terms" element={<Pages.Terms />} />
        <Route path="/terms-and-conditions" element={<Pages.Terms />} />
        <Route path="/deposit" element={<Pages.Deposit />} />
        <Route path="/withdraw" element={<Pages.Withdraw />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
};

export function App() {
  return (
    <BrowserRouter>
      <DisplayContextProvider>
        <div className="App">
          <Page />
        </div>
      </DisplayContextProvider>
      <ToastContainer
        autoClose={3500}
        position="top-right"
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </BrowserRouter>
  );
}
