export const calculatePayout = (stake: number, legs: number) => {
  if (isNaN(stake)) {
    return "Please enter a valid stake";
  }
  switch (legs) {
    case 2:
      return stake * 3;
    case 3:
      return stake * 6;
    case 4:
      return stake * 10;
    case 5:
      return stake * 20;
  }
  return "Please choose between 2 and 5 legs";
};
