import * as React from "react";
import * as style from "./MobileHome.style";
import bg from "../../assets/mobile-sports-betting-game-bg.png";
import hero from "../../assets/hero.png";
import title from "../../assets/title-image.png";
import { Icon } from "../../components/index";
import { colors } from "../../styles/index";
export const MobileHome: React.FC<{
  onSubmit: () => void;
  loading: boolean;
  email: string;
  emailError: string;
  setEmail: (email: string) => void;
  shareLink: string;
}> = ({ onSubmit, loading, email, setEmail, emailError, shareLink }) => {
  return (
    <div className={style.component}>
      <div className={style.container}>
        <img className={style.background} src={bg} />
        <div className={style.content}>
          {/* <Icon.Logo
            width={155}
            height={24}
            fill={colors.primary}
            className={style.logo}
          />
          <div className={style.title}>
            Daily Fantasy Sports for the Curious Fan.
          </div>
          <div className={style.title}>Coming September 6th.</div>
          <div className={style.subTitle}>
            <div>Discover. Pick. Win.</div>
          </div> */}
          <div className={style.titleImageContainer}>
            <img
              className={style.titleImage}
              src={title}
              alt="units daily fantasy"
            />
          </div>
          <div className={style.inputWrapper}>
            <div className={style.inputContainer}>
              <input
                className={style.input}
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter your email..."
              />
            </div>
          </div>
          <div className={style.inputWrapper}>
            <div className={style.signUpButton} onClick={onSubmit}>
              {loading ? <Icon.Spinner size={18} /> : "Step Inside"}
            </div>
          </div>
          {emailError && <div className={style.error}>{emailError}</div>}
          {!!shareLink && (
            <div>
              <div className={style.shareTitle}>Thank you!</div>
              <div className={style.shareDetail}>
                We have added your email address to the list — stay tuned for
                launch on September 6th.
              </div>
              <div className={style.shareTitle}>
                Want to earn in-app site credit when we launch?
              </div>
              <div className={style.shareDetail}>
                Get $5 in site credit for every friend that signs up using your
                unique link:
              </div>
              <div
                className={style.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(shareLink || "");
                }}
                id="submit_button"
              >
                Copy to Clipboard
              </div>
              {/* <a
                className={style.shareTitle}
                href={shareLink}
                style={{ color: colors.primary }}
              >
                {shareLink}
              </a> */}
            </div>
          )}
          {/* <div className={style.heroImageContainer}>
            <img className={style.heroImage} src={hero} />
          </div> */}
        </div>
      </div>
      <div className={style.footer}>
        <div>
          <a className={style.contactUs} href="mailto:sup@playunits.co">
            Contact us
          </a>
        </div>
        <div>&copy; Copyright 2023</div>
      </div>
    </div>
  );
};
