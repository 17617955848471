import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  backgroundColor: Style.colors.background.base,
  fontFamily: Style.font.main,
  minHeight: "100vh",
});

export const container = s({
  width: "100vw",
  position: "relative",
});

export const background = s({
  width: "calc(100% - 25px)",
  position: "relative",
  zIndex: 1,
  paddingLeft: "25px",
});

export const content = s({
  position: "absolute",
  top: "2vw",
  zIndex: 2,
  textAlign: "center",
  width: "100%",
});

export const logo = s({
  width: 155,
  height: 24,
  margin: "auto",
  marginBottom: "2vw",
});

export const title = s(
  {
    fontSize: "6vw",
    lineHeight: "6vw",
    letterSpacing: "-.02em",
    marginBottom: "1.5vw",
    color: Style.textColors.main,
    maxWidth: "70vw",
    margin: "auto",
    fontWeight: 700,
    fontStretch: "expanded",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: "4vw",
      lineHeight: "4vw",
    },
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: 58,
      lineHeight: "58px",
    },
  ),
);

export const smallTitle = s(
  {
    fontSize: "5.5vw",
    lineHeight: "5.5vw",
    letterSpacing: "-.02em",
    marginBottom: "1.5vw",
    color: Style.textColors.main,
    maxWidth: "70vw",
    margin: "auto",
    fontWeight: 700,
    fontStretch: "expanded",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: "3.5vw",
      lineHeight: "3.5vw",
    },
  ),
  media(
    { minWidth: Style.screensize.lgMin },
    {
      fontSize: 50,
      lineHeight: "50px",
    },
  ),
);

export const subTitle = s({
  fontSize: "1.6vw",
  lineHeight: "2vw",
  fontWeight: 600,
  letterSpacing: "-.01em",
  color: Style.colors.translucent650,
  marginBottom: "2vw",
});

export const inputContainer = s({
  maxWidth: 544,
  borderRadius: 96,
  border: "2px solid rgba(218, 218, 218, 0.16)",
  padding: "12px 12px 12px 28px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: Style.colors.darkTranslucent500,
  boxShadow:
    "0px 16px 24px -4px rgba(0, 0, 0, 0.7), 0px 0px 52px rgba(255, 230, 0, 0.16), inset 0px 0px 8px rgba(0, 0, 0, 0.55)",
  marginBottom: "1vw",
  backdropFilter: "blur(30px)",
});

export const input = s({
  flex: 1,
  backgroundColor: "transparent",
  border: "none",
  outline: "none",
  color: Style.textColors.subtle,
  fontFamily: Style.font.second,
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "24px",
  width: "calc(100% - 165px)",
});

export const signUpButton = s({
  backgroundColor: Style.colors.primary,
  color: Style.textColors.inverse,
  padding: "12px 24px",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  cursor: "pointer",
  transition: ".2s ease-in",
  boxShadow: "0px 0px 48px rgba(255, 230, 0, 0.5)",
  borderRadius: "57px",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.white,
    },
  },
  flex: "0 0 165px",
  fontStretch: "125%",
});

export const copyButton = s({
  backgroundColor: Style.colors.primary,
  color: Style.textColors.inverse,
  padding: "12px 24px",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  cursor: "pointer",
  transition: ".2s ease-in",
  boxShadow: "0px 0px 48px rgba(255, 230, 0, 0.5)",
  borderRadius: "57px",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.white,
    },
  },
  fontStretch: "125%",
  maxWidth: 300,
  margin: "auto",
  marginBottom: 24,
});

export const error = s({
  marginTop: "-.5vw",
  marginBottom: ".5vw",
  lineHeight: "12px",
  height: "20px",
  paddingTop: 8,
  fontSize: 14,
  fontWeight: 500,
  color: Style.textColors.error,
});

export const shareSection = s({
  maxWidth: 800,
  margin: "auto",
});

export const shareTitle = s({
  fontSize: 24,
  marginBottom: 4,
  lineHeight: "28px",
  fontWeight: 500,
  color: Style.textColors.main,
  textShadow: "0px 0px 32px rgba(0, 0, 0, 0.6)",
});

export const shareDetail = s({
  fontSize: 20,
  lineHeight: "24px",
  marginBottom: 24,
  fontWeight: 500,
  color: Style.textColors.subtle,
});

export const heroImageContainer = s({
  textAlign: "center",
});

export const heroImage = s(
  {
    width: "40%",
  },
  media(
    { minWidth: Style.screensize.lgMin },
    {
      width: "30%",
    },
  ),
  media(
    { minWidth: Style.screensize.xlMin },
    {
      width: "20%",
    },
  ),
);

export const titleImageContainer = s({
  textAlign: "center",
  marginBottom: 24,
});

export const titleImage = s(
  {
    width: "60%",
  },
  media(
    { minWidth: Style.screensize.xlMin },
    {
      width: "40%",
    },
  ),
);

export const footer = s({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "space-between",
  fontSize: 16,
  color: Style.textColors.light,
  fontWeight: 400,
  fontFamily: Style.font.second,
  padding: 32,
});

export const contactUs = s({
  color: "inherit",
  textDecoration: "none",
});
