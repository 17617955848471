import React from "react";
import { colors } from "../../styles";

interface ComponentProps {
  className?: string;
  fill?: string;
  stroke?: string;
  height?: number;
  width?: number;
  size?: number;
  onClick?: () => void;
}

export const Icon: (Icon: any) => React.FC<ComponentProps> =
  (Icon: any) =>
  ({ fill, stroke, height, width, size, onClick, className }) => {
    return (
      <div className={className}>
        <Icon
          height={height || size}
          width={width || size}
          fill={fill || colors.darkTranslucent750}
          stroke={stroke || colors.darkTranslucent750}
          onClick={onClick}
        />
      </div>
    );
  };

export const Logo: React.FC<Omit<ComponentProps, "stroke">> = ({
  fill,
  height,
  width,
  size,
  onClick,
  className,
}) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || size || "90"}
        height={height || size || "14"}
        viewBox="0 0 90 14"
        fill="none"
      >
        <path
          d="M0 0.22876H6.84367V5.71676C6.84367 7.18676 7.4725 8.77926 10.3227 8.77926H10.3553C13.1483 8.77926 13.8343 7.18676 13.8343 5.71676V0.22876H20.678V6.41093C20.678 9.08143 19.6572 13.7936 10.7637 13.7936H9.86533C0.988167 13.8018 0 9.0896 0 6.4191V0.22876Z"
          fill={fill || "#EDEDED"}
        />
        <path
          d="M29.2531 0.22876L35.5251 6.28843V0.22876H42.3361V13.5649H35.4026L28.3792 6.90093V13.5649H21.5601V0.22876H29.2531Z"
          fill={fill || "#EDEDED"}
        />
        <path
          d="M50.135 13.5649H43.3403V0.22876H50.135V13.5649Z"
          fill={fill || "#EDEDED"}
        />
        <path
          d="M56.693 4.81843H50.9355V0.22876H69.2534V4.81843H63.4959V13.5649H56.6849V4.81843H56.693Z"
          fill={fill || "#EDEDED"}
        />
        <path
          d="M69.5635 9.17934V9.03234H77.5423C77.5586 9.604 77.7791 10.4697 79.6575 10.4697C81.25 10.4697 81.6991 10.0205 81.6991 9.53867V9.4815C81.6991 9.04867 81.5195 8.5995 79.2246 8.53417L76.4071 8.44434C70.9191 8.26467 69.7758 6.31284 69.7758 4.214V4.05067C69.7758 1.5435 71.7848 0 78.2365 0H80.515C87.669 0 89.335 1.90284 89.335 4.19767V4.2875H81.5195C81.4868 3.85467 81.2336 3.2095 79.4778 3.2095C77.8608 3.2095 77.5423 3.46267 77.5423 3.91184V3.9445C77.5423 4.44267 78.065 4.69583 80.123 4.753L82.8098 4.8265C88.0038 4.9735 89.8331 6.223 89.8331 8.93434V9.1875C89.8331 11.5558 88.306 13.8507 80.7273 13.8507H78.3753C70.2985 13.8343 69.5635 10.8943 69.5635 9.17934Z"
          fill={fill || "#EDEDED"}
        />
      </svg>
    </div>
  );
};
