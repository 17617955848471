import * as React from "react";
import * as style from "./Withdraw.style";
import bg from "../../assets/mobile-sports-betting-game-bg.png";
import hero from "../../assets/units-daily-fantasy-mobile.png";
import { Icon } from "../../components/index";
import { colors } from "../../styles/index";
import { useSearchParams } from "react-router-dom";
export const Withdraw: React.FC<{}> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const result = React.useMemo(() => {
    return searchParams.get("result");
  }, [searchParams]);

  React.useEffect(() => {
    if (!!result) {
      window.location.href = `playunits://withdraw?result=${result}`;
    }
  }, [result]);

  return (
    <div className={style.component}>
      <div className={style.container}>
        <img className={style.background} src={bg} />
        <div className={style.content}>
          <Icon.Logo
            width={155}
            height={24}
            fill={colors.primary}
            className={style.logo}
          />
          <div className={style.title}>
            {result === "success"
              ? "Success! You’re withdrawal request is being review by our team and should arrive within 48-72 hours."
              : "We're sorry, your withdrawal request didn't go through. Please try again"}
          </div>
          <div className={style.subTitle}>
            <div>
              If this window doesn't close automatically, please manually close
              the in-app browser
            </div>
          </div>
          <div className={style.inputWrapper}></div>
          <div className={style.inputWrapper}></div>
          {/* <div>
            <div className={style.shareTitle}>Thank you!</div>
            <div className={style.shareDetail}>
              We have added your email address to the list — stay tuned for
              launch on September 6th.
            </div>
            <div className={style.shareTitle}>
              Want to earn in-app site credit when we launch?
            </div>
            <div className={style.shareDetail}>
              Get $5 in site credit for every friend that signs up using your
              unique link:
            </div>
          </div> */}
          <div className={style.heroImageContainer}>
            <img className={style.heroImage} src={hero} />
          </div>
          <div className={style.footer}>
            <div>
              <a className={style.contactUs} href="mailto:sup@playunits.co">
                Contact us
              </a>
            </div>
            <div>&copy; Copyright 2023</div>
          </div>
        </div>
      </div>
    </div>
  );
};
