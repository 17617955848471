// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Mona-Sans.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Mona Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2 supports variations\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2-variations\");\n  font-weight: 200 900;\n  font-stretch: 75% 125%;\n}\n", "",{"version":3,"sources":["webpack://./styles/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB;sEACmD;EACnD,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":["@font-face {\n  font-family: \"Mona Sans\";\n  src: url(\"Mona-Sans.woff2\") format(\"woff2 supports variations\"),\n    url(\"Mona-Sans.woff2\") format(\"woff2-variations\");\n  font-weight: 200 900;\n  font-stretch: 75% 125%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
