export const background = {
  base: "#000000",
  card: "#1C1D1E",
  layered: "#2A2C2E",
  cardHighlight: "rgba(233,244,255,.07)",
};

export const translucent950 = "rgba(255, 255, 255, 0.95)";
export const translucent880 = "rgba(255, 255, 255, 0.88)";
export const translucent850 = "rgba(255, 255, 255, 0.85)";
export const translucent700 = "rgba(255, 255, 255, 0.7)";
export const translucent650 = "rgba(255, 255, 255, 0.65)";
export const translucent400 = "rgba(255, 255, 255, 0.4)";
export const translucent150 = "rgba(255, 255, 255, 0.15)";

export const darkTranslucent20 = "rgba(0, 0, 0, 0.02)";
export const darkTranslucent30 = "rgba(0, 0, 0, 0.03)";
export const darkTranslucent100 = "rgba(0, 0, 0, 0.1)";
export const darkTranslucent200 = "rgba(0, 0, 0, 0.2)";
export const darkTranslucent250 = "rgba(0, 0, 0, 0.25)";
export const darkTranslucent300 = "rgba(0, 0, 0, 0.3)";
export const darkTranslucent500 = "rgba(0, 0, 0, 0.5)";
export const darkTranslucent650 = "rgba(0, 0, 0, 0.65)";
export const darkTranslucent700 = "rgba(0, 0, 0, 0.7)";
export const darkTranslucent750 = "rgba(0, 0, 0, 0.75)";

export const white = "#FFFFFF";
export const black = "#000000";
export const yellow = "#FFE600";
export const primary = yellow;
export const danger = "#F15858";

export const gradients = {
  pickedCard:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0) 100%), rgba(0, 0, 0, 0.2)",
};
