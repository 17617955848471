import { style as s, media } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  backgroundColor: Style.colors.background.base,
  fontFamily: Style.font.main,
});

export const container = s({
  width: "100vw",
  position: "relative",
});

export const background = s({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
});

export const content = s({
  position: "relative",
  zIndex: 2,
  textAlign: "center",
  padding: 16,
});

export const logo = s({
  width: 155,
  height: 24,
  margin: "auto",
  marginBottom: 20,
});

export const title = s({
  fontSize: 40,
  lineHeight: "44px",
  letterSpacing: "-.02em",
  marginBottom: 20,
  color: Style.textColors.main,
  margin: "auto",
  fontWeight: 700,
  fontStretch: "expanded",
});

export const subTitle = s({
  fontSize: 24,
  lineHeight: "30px",
  fontWeight: 400,
  letterSpacing: "-.01em",
  color: Style.colors.white,
  marginBottom: 20,
});

export const inputWrapper = s({
  width: "100%",
});

export const inputContainer = s({
  borderRadius: 96,
  border: "2px solid rgba(218, 218, 218, 0.16)",
  padding: "12px 12px 12px 28px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: Style.colors.darkTranslucent500,
  boxShadow:
    "0px 16px 24px -4px rgba(0, 0, 0, 0.7), 0px 0px 52px rgba(255, 230, 0, 0.16), inset 0px 0px 8px rgba(0, 0, 0, 0.55)",
  backdropFilter: "blur(30px)",
  marginBottom: "16px",
});

export const input = s(
  {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: Style.textColors.subtle,
    fontFamily: Style.font.second,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "24px",
    width: "100%",
    paddingRight: 4,
  },
  media(
    { minWidth: Style.screensize.smMin },
    {
      fontSize: 28,
      lineHeight: "28px",
    },
  ),
);

export const signUpButton = s({
  backgroundColor: Style.colors.primary,
  color: Style.textColors.inverse,
  padding: "12px 24px",
  fontWeight: 700,
  fontSize: 18,
  lineHeight: "24px",
  cursor: "pointer",
  transition: ".2s ease-in",
  boxShadow: "0px 0px 48px rgba(255, 230, 0, 0.5)",
  borderRadius: "57px",
  fontStretch: "125%",
  zIndex: 10,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.white,
    },
  },
  marginBottom: "16px",
});

export const error = s({
  marginBottom: ".5vw",
  lineHeight: "12px",
  height: "20px",
  paddingTop: 8,
  fontSize: 14,
  fontWeight: 500,
  color: Style.textColors.error,
});

export const shareLink = s({
  fontSize: "6vw",
  lineHeight: "7vw",
  marginBottom: 12,
  fontWeight: 500,
  color: Style.textColors.main,
  textShadow: "0px 0px 32px rgba(0, 0, 0, 0.6)",
  padding: `0px 16px`,
});

export const shareTitle = s({
  fontSize: 24,
  marginBottom: 4,
  lineHeight: "28px",
  fontWeight: 500,
  color: Style.textColors.main,
  textShadow: "0px 0px 32px rgba(0, 0, 0, 0.6)",
});

export const shareDetail = s({
  fontSize: 20,
  lineHeight: "24px",
  marginBottom: 24,
  fontWeight: 400,
  color: Style.textColors.subtle,
});

export const heroImageContainer = s({
  width: "100vw",
  marginLeft: -16,
});

export const heroImage = s({
  width: "100%",
});

export const footer = s({
  padding: "48px 16px 16px",
  display: "flex",
  justifyContent: "space-between",
  fontSize: 16,
  color: Style.textColors.light,
  fontWeight: 400,
  fontFamily: Style.font.second,
});

export const contactUs = s({
  color: "inherit",
  textDecoration: "none",
});
