import React, { useState } from "react";
import * as Components from "../components";
import { style as s } from "typestyle";

export const PrivacyPolicy: React.FC = () => {
  return (
    <div style={{ color: "white", padding: 40 }}>
      <Components.MarkdownRenderer>{`# PRIVACY POLICY

  Effective April 1, 2023
  Celly Sports Inc. (“**Units**”, “**Company**”, “**we**”, “**us**”, “**our**”), a Delaware corporation with offices in New York, respects and protects the privacy of those individuals (“**Guests**”) who visit Playunits.co (“**Site**”); download and use the mobile application (“**App**”) (“**Users**”); or use any other features, tools, materials, or services (including co-branded or affiliated services) offered from time to time by Units or its affiliate companies in connection with the Site or related mobile app services offered through the Units mobile application (the “**App**”, together with the Site, the “**Services**”). Units has a strict policy ensuring the privacy of Guests and Users. This policy (“**Privacy Policy**” or the “**Policy**”) outlines the different types of information we may collect from Guests and Users, the manner in which we collect it, and how we use, maintain, protect, and disclose such information. Additionally, the Policy contains further rights and protections that may apply to information collected from certain Guests and Users. All terms that are capitalized but undefined herein shall have the meaning prescribed in the Units Terms of Service or any addenda thereto (“**Terms and Conditions**”).
  Units provides this Privacy Policy to help you make an informed decision about whether to use or continue using the Services. By accessing or using the Services, you agree to the terms of the Policy. The Policy may change from time to time in the sole discretion of Units; such changes are deemed accepted by your continued use of the Services. Please check the Policy periodically for updates. The Policy should be read in conjunction with Units’ Terms and Conditions. By downloading, accessing, or interacting with the Services, you consent to collecting information and using practices described herein. If you have any concerns about providing information or your information being used as described in this Privacy Policy, you should not use the Services.
  
  ## 1. Scope of Policy
  
  The Units Privacy Policy (the “**Policy**”) protects the privacy of individuals who visit Units’ website or download Units’ application (“**Guests**”) and individuals who Create an Account (“**Users**”). The Policy applies to information automatically collected when Guests or Users use the Services or information provided directly by Guests and Users. The Policy does not apply to information collected offline or by other means, including via other websites operated by or in conjunction with Units, including affiliates, partners, subsidiaries, and assignees (“**Offline Information**”). Furthermore, Units is not responsible for the privacy policies or data collection practices of Third-Parties linked to, or from, PlayUnits.co or the Units Application or the content therein. Users are encouraged to read the privacy policies of all linked Third-Parties.
  
  ## 2. How We Collect Your Information
  
  Units collects several different types of information about our Guests and Users, including but not limited to Information by which you may be personally identified, such as name, e-mail address, or any other identifier by which you may be contacted online or offline (“**Personal Data**”); that is about you but individually does not identify you; and about your internet connection, the equipment you use to access our Services and usage details. We collect this information directly from you when you provide it to us, automatically as you navigate through the Services, or from third parties, for example, our business partners.
  
  ### Information you provide to us
  
  When you sign up to use the Services or when you request certain materials, you may be required to provide personal information (“**Personal Information**”). You may be asked to provide your name, e-mail address, mailing address, telephone number, date of birth, gender, credit card billing information, other payment information regarding third-party payment providers, profile photo, username, password, location, preferences, contest or promotion submissions, transaction history, email correspondences, survey responses, dialog boxes or chats, communications sent directly from Units such as feedback requests, newsletter subscriptions and similar services Units offers, and any other information you provide Units when participating in the Services such as posting information on public areas of the Services. All information posted on the public areas of the Services should be done so at your own risk. As we cannot control the actions of other Guests and Users of the Services who may view such posted information, we make no warranty that your posts or any information you share in public areas of the Services will not be viewed by unauthorized individuals. Units may be required to store and share some information internally and with our partners, including information pertaining to your deposits, personally identifiable information, withdrawals, and other activity.
  
  ### Information Automatically Collected through your use of the Services
  
  As you navigate and interact with our Services, upon choosing to participate in certain activities such as account registration, downloading the app, running the services, or participating in Contests, we may use automatic data collection technologies to collect certain demographic information and information about your user history, devices used, internet protocol (“**IP**”) address, operating system, browser type, browsing actions, and pattern. The information we collect automatically is statistical data and does not include Personal Information. Units may maintain the information or associate it with Personal Information we collect in other ways. This is done to enable Units to improve the Services and deliver a better and more personalized service, including enabling us to estimate our audience size and usage.
  
  Units uses various technological tools to track and collect information regarding individuals’ use of the Services (see “Third-Party Advertising and Analytics” Section). We may also collect Personal Information from third parties, including but not limited to marketing partners, social networks, identity verification services, and anti-fraud services. Units may combine information collected from you with certain of your information that Units obtained from such Third-Parties, in addition to information derived from another subscription, product, or service provided by Units. Furthermore, upon a User’s decision to log in, access, contact, communicate, or otherwise connect to Units through a social networking service, we may collect the User ID and username associated with the specific social networking service, in addition to any information made public by the User using the specific social networking service. Furthermore, Units may also collect the certain information that you authorized the specific social networking service to share with Units (eg. User ID, public profile information, email address, birthday, friends list, and pages “liked”).
  
  Units may automatically collect certain information about the computer or device used to access the Services, including but not limited to operating system; type of hardware; numbers or codes unique to the User’s specific device (eg. Android Ad ID), device information, default device language, Internet Protocol address, the location of your device (at a GPS level), and app usage information. We may also collect information related to the ways in which you interact with the Site and Services, as discussed in the “Log File Information” and “Clear GIFs Information” sections below. As described further below in the “Third Party Advertising and Analytics” section, we may use third-party analytics providers and technologies, including cookies and similar tools, to assist in collecting and analyzing this information. This data also may be linked to your other information, including your location data. The technologies used to automatically collect information include the following:
  
  #### Cookies
  
  When you visit our website, Units may send one or more cookies - small files - to your computer or other device, which may enable us or others to uniquely identify your browser. Units uses both session cookies and persistent cookies. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the site but can be removed on your web browser. Session cookies typically disappear after your browser is closed. Most browsers automatically accept cookies, but you may be able to modify your browser settings to decline cookies. If you choose to decline cookies, please note that you may not be able to sign in, customize, or use some of the interactive features of the Site or Services, and the advertisements you see may not be as relevant to you.
  
  #### Log Files
  
  When you use the Services, our servers may, from time to time, automatically record certain information that your device sends when visiting websites and using certain apps. These server logs may include information including your web or application request; your IP address, browser type, and browser language; your referring and exit pages; URLs; platform type, number of clicks, domain names, landing pages, pages viewed, and the order thereof; the amount of time spent on certain pages; information on mouse hovering including location and the amount of time spent on each area of a given page; the date and time that you used or accessed the Services; how often you use the Services; error logs and other similar information that may identify your browser.
  
  #### Clear GIF Information/Web Beacons
  
  When you use our Services, we may employ technology such as “Clear GIFs”, used to track Users’ online usage patterns. Units we may also use Clear GIFs in HTML-based emails which we send to Users to track which emails are opened by whom.
  
  #### Heat Mapping/Session Recording
  
  Heat mapping services are used to display the areas of a page that Users most frequently move or click the mouse. The information derived therefrom identifies and locates points of interest. The Services enable Units to monitor and analyze web traffic and track User behavior, including recording sessions and availing such recordings for visual playback at a later time.
  
  #### Location
  
  The Services collect certain information regarding the location of the device being used to access the Services, including IP address, zip code, and GPS functionality on a mobile device, which may be used to identify violations of the Terms and Conditions or of the rules of a specific Contest or promotion. Disabling location data impairs the functionality of the Services, including but not limited to prohibiting Users from playing Contests. This information may at a later time be connected to personal information about Users which has been collected from other sources or which the User provided to Units. Such location-based information may be shared with Units’ agents, vendors or advertisers. If accessing the Services using a mobile device, you can disable the GPS and other location-tracking functions if your device allows it and if you don’t wish to provide us with location-tracking information via your mobile device. See the manufacturer’s instructions on your specific mobile device for additional details.
  
  ## 3. How We Use Your Information
  
  Units may use aggregate (anonymized) information about Users or information that does not identify any individual without restriction. The collection of User Personal Information is limited to what is needed for the purposes of Units. Unless you consent, or Units is required by law, we will only use the Personal Information for the purposes for which it was collected. Should Units process your Personal Data at a later time for another purpose, we will seek to obtain your further consent, except where the other purpose is compatible with the original purpose. Units will only maintain your Personal Information for so long as is required for those purposes. Furthermore, Units will retain Users’ Personal Information for as long as necessary to comply with applicable laws and regulations, and to assist in resolving disputes.
  
  Your Personal Information is used for a variety of purposes, including but not limited to collecting or disbursing payment for transactions made through the Services; enabling use of the Services; providing information, products, or services requested by Users; customizing and personalizing the content from the Services; providing customer support and general customer service; transmitting administrative messages regarding the Services to Users; carrying out Units obligations; enforcing Units’ rights arising from any contracts entered into between Units and a User such as for billing and collection; notifying Users of updates or changes to the Services or components thereof; monitoring and analyzing trends, usage, and activities pertaining to the Services; investigating and preventing fraudulent transactions and unauthorized access to the Services; contacting Users about goods and services of Units or Third-Parties which may be of interest to you; violations of the Terms and Conditions; other illegal acts; sharing testimonials or reviews posted to the Services affiliated social media; in any other manner described by Units when providing the Personal Information; for any other purpose with User consent. Note that Units’ agreements with these Third-Parties and service providers require they use reasonable efforts to secure your Personal Information.
  
  If you do not wish for your Personal Information to be used in the manner described above, adjust your User preferences in your account profile or by unsubscribing from our marketing communications. If you win a promotion or contest run by Units, we may be required to publish your name or make it available upon request. Units may also publish a profile page including but not limited to your username; registration date; results or statistics from previous Contests, promotions, or contests you participated in; state of residence; information posted by you to public areas of the Services; and profile picture is uploaded. Please note that when you post your Personal Information in public areas of the Services, you acknowledge and agree and agree that the certain Personal Information will be publicly available. We may also disclose Personal Information in the following instances:
  
  1.  When participating in Contests, Units may publish your username, name, and/or profile picture on a list of participants, along with a link to your profile page.
  2.  Where you consented, Units may share your Personal Information with certain Third-Parties for their commercial or marketing purposes, whether in conjunction with your relationship with Units, as part of a specific program or feature, or otherwise.
  3.  Certain components of Personal Information may be disclosed to third parties for targeted online or mobile advertising. See the “Third Party Advertising and Analytics” section.
  4.  Your Personal Information may be shared in other situations with your consent, including as Outlined herein).
  5.  Units reserves the right to disclose or share Personal Information that Units, in its sole discretion, believes that in good faith it is either necessary or appropriate to enforce the Terms and Conditions, or any other agreement we have with you, in order to enable Units to protect against liability, harm, or any actual or suspected fraudulent, abusive, or unlawful uses of the Services; to investigate, respond to, and defend against Third-Party claims, allegations, subpoenas, court orders, other official requests; to comply with State and Federal laws and regulations; to protect the security or integrity of the Services; to report violations of Units eligibility rules to Third-Parties; and to protect the rights, property, or safety of Units, our Guests, our Users or others. Additionally, there may be a disclosure of your Personal Information to an entity related to a potential Units merger, acquisition, sale, bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors; application of laws or equitable principles affecting creditors’ rights generally; or other.
      Units shall retain your Personal Information as long as necessary to fulfill the above purposes in its sole discretion and as required to comply with legal and regulatory obligations, to resolve disputes, and to enforce Units policies unless we are required by law to retain your Personal Information longer. To ensure business continuity for the activities above and to ensure security under applicable regulations, Units will back up certain information. Such information may be retained for a longer period of time than the original information. Still, otherwise, the retention periods for your Personal Information are determined by considering the information type and purpose, in light of the requirements applicable to the situation, and also considering the need to destroy outdated, unused information at the earliest reasonable time.
  
  ## 4. How We Protect Your Information
  
  Units has implemented industry-standard technical and organizational measures which are designed to secure the Personal Information of Users from accidental loss, unauthorized access or use, alteration, and disclosure. The measures taken are designed to provide a level of security appropriate to the risk of processing your Personal Data. All information you provide to Units is stored on our secure servers behind firewalls. Users may also take an active role in securing their information by ensuring the confidentiality of any passwords given to you by Units for access to certain parts of the Services. Additionally, you are cautioned about sharing Personal Information in public areas of the Services. The information that you share in such areas may be viewed by any other User of the Services and in certain instances by some Guests. Though Units is committed to protecting your Personal Information, it is understood that transmission of information via the internet is not completely secure. As such, we cannot guarantee the security of the Personal Data you transmit to the Services. All transmissions of Personal Information are done at the sole risk of the User, Guest, or individual, and Units is not liable for circumvention of any privacy settings or security measures contained in the Services.
  
  ## 5. Your Choices for Your Information
  
  You may review and edit your Personal Information by visiting your personal account profile page. Further, you may email Units to request access to, correct, or delete any Personal Information you have already provided to us. Units may decline to accommodate a request to change Personal Information if Units believes the change may violate any law or legal requirement or cause the Personal Information to be incorrect.
  
  ## 6. Third-Party Advertising and Analytics
  
  Certain advertisements which appear within the Services are delivered by Units’ advertising partners. Such advertising partners may use cookies and similar technologies, often in conjunction with unique (anonymous) cookie identifiers, to collect information about user activities and interests, including the technologies described above. Some such advertising partners may use mobile device identifiers, such as Apple IDFAs and Google/Android Advertising IDs, enabling companies to recognize your computer or device each time they send you an online or mobile advertisement. In this way, the ad companies may compile information about where you, or others who are using your computer or device, saw their advertisements and measure your interaction with each ad. This information allows ad companies to deliver targeted advertisements that may be of increased interest to you, both on our Services and elsewhere online.
  
  ## 7. California Residents
  
  If you are a California resident, the California Consumer Protection Act (“**CCPA**”) may permit you to request that Units provide you with the categories of Personal Information we have collected or disclosed about you in the last twelve months; the categories or sources of such information; the business or commercial purpose for collecting or selling your Personal Information and the categories of third parties with whom we shared Personal Information; provide you with access to and/or a copy of certain Personal Information we hold about you; and delete certain Personal Information we have about you.
  
  You may have the right to receive information about the financial incentives that we offer to you for your Personal Information (if any). You also have the right not to be discriminated against (as provided for in applicable law) for exercising your rights. Please note that if you ask us to delete certain information, you may no longer be able to access or use some of Units’ Services. Please note the CCPA sets forth obligations for businesses that “sell” personal information to Third Parties. Units does not engage in such activity and have not engaged in such activity in the past twelve months. Units does however share certain information and allow parties to collect certain information about your activity, for example through cookies, as explained in the “Third Party Advertising and Analytics” section.
  
  California Civil Code Section 1798.83 permits California residents who use the Units Services to request certain information regarding Units’ disclosure of Personal Information to third parties for their direct marketing purposes. Units does not share your Personal Information with third parties for their own direct marketing purposes. If you are a California resident under the age of 18 and a registered user of Units Services, you are permitted to request and obtain the removal of content or information you have publicly posted. To make any such request, please contact Units via email at Support@PlayUnits.co. Please be aware that such a request does not ensure complete or comprehensive removal of the content or information you have posted and that there may be circumstances in which the law does not require or allow removal even if requested. Furthermore, California residents who have an established business relationship with Units may opt out of disclosure of their Personal Information to third parties for their marketing purposes.
  
  ## 8. Children Under the Age of Eighteen
  
  Units Services are not intended for individuals under the age of Eighteen (18). Units does not knowingly solicit or collect Personal Information from individuals under the age of 18. If Units becomes aware of any Personal Information collected from individuals under the age of 18, it will be deleted as soon as practicable. If you believe that an individual under the age of 18 is using the Service, or that Units may possess Personal Information of an individual under the age of 18, please advise via email at Support@PlayUnits.co.
  
  You are not permitted to access the Services if you are under 18 years of age, or under the minimum age in your state of residence (eg., 21 for Massachusetts residents). Units does not knowingly collect Personal Information from individuals under the age of 18. No individual under the age of 18 should register for the Services or engage in playing Contests or use any interactive component of the Services, or provide any Personal Information including name, address, telephone number, email address, screen name, or user name. If Units becomes aware of any Personal Information collected from an individual under the age of 18, that information will be deleted.
  
  ## 9. International Transfer of Personal Information
  
  Units is currently only available in the United States. As such, Units stores the personal information of its users and guests within the United States. If you are accessing Units from outside the United States, you agree to the transfer of your Personal Information to the United States. The laws of the United States may differ from those of other countries and may be less protective of your Information than other countries. You agree to any transfer or processing of your Information in accordance with this Policy.
  
  ## 10. Links to Third-Party Websites and Services
  
  Units may provide links to Third-Party websites, applications, and services, including links contained in advertisements. You agree that when you click a link, any data you provide thereafter is subject to the privacy policies of the Third-Party and is not governed by this Policy. Units is not responsible for the content, safety, privacy or security of any Third-Party website, application, or service.
  
  ## 11. Changes to this Policy
  
  This Policy may be amended from time to time, reflected in the “Last update posted” date at the bottom of this page. Units may advise you via email of future material amendments to this Policy, but please revisit this Policy periodically to remain aware of any changes. Use of the Services after an update has been posted constitutes acceptance of the Policy, including any changes made.
  
  ## 12. Questions
  
  If you have any questions about this Policy, please contact Units by emailing Support@PlayUnits.co.
  This Policy was last updated on: April 1, 2023
  `}</Components.MarkdownRenderer>
    </div>
  );
};
